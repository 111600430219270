import {
    SEVERSTATUS,
    DEPLOYMENTTIPS,
    CUSTOMERSTATUS,
    BTNLOADING
} from './constants';

// 定义一个状态
let initialState = {
    serviceStatus: false,//条款状态
    serviceData: "",//条款内容
    deploymentTips: "",//部署步骤提示
    customer: false,//客服弹窗状态
    btnLoading: false,//按钮loading状态
};

// 利用reducer将store和action串联起来
function reducer(state = initialState, action) {
    switch (action.type) {
        case CUSTOMERSTATUS:
            return { ...state, customer: action.data };
        case SEVERSTATUS:
            return { ...state, serviceStatus: action.status, serviceData: action.data };
        case DEPLOYMENTTIPS:
            return { ...state, deploymentTips: action.data };
        case BTNLOADING:
            return { ...state, btnLoading: action.status };
        default:
            return state;
    }
}

export default reducer;