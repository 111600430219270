// 产品-软件服务-客户案例
import React, { Component } from 'react'
import Title from '../../reuseComponent/Title'
import './style.scss'

import SwiperCore, { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react' // 引入js
import 'swiper/swiper.min.css' // 引入样式
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import { getCaseDataList } from '../../API/agentset'
import Loading from '../../reuseComponent/Loading'
import Rasterization from '../../tool/Rasterization'
import { Link } from 'react-router-dom';

import left from '../../images/qianjin 2.png'
import right from '../../images/qianjin 1.png'

SwiperCore.use([Navigation, Autoplay]);
export default class Bus150520yySwitJump extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CaseDate: '',
            isLoading: true,
        }
    }
    componentDidMount() {
        this.getList()
    }
    getList() {
        let data = {
            "populate": "img",
            "sort": "createdAt:desc",
            "pagination[pageSize]": 5
        }
        getCaseDataList(data).then(res => {
            this.setState({
                CaseDate: res.data,
                isLoading: false,
            })
        })
    }
    render() {
        let data = this.props.data
        let { isLoading, CaseDate } = this.state
        let title = {
            name: data.title,
            content: data.abstract,
            titleStyle: {
                color: "#000",
                textAlign: "center"
            },
            abstractStyle: {
                color: "#F0F0F0",
                textAlign: "center"
            }
        }
        const setting = {
            module: { Navigation, Pagination },
            loop: true,
            loopedSlides: 5,
            slidesPerView: 5,
            centeredSlides: true,
            coverflowEffect: {
                stretch: "100%",
                depth: 60,
                modifier: 2,
            },
            effects: "cards",
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                disabledClass: 'disable'
                // 当导航按钮变为不可用时添加的class,也就是当swiper索引为0时上一张没有prevEl的class类名就会添加一个disable，也就是.swiper-button-prev .disable
            }
        }
        if (isLoading) {
            return <Loading />
        } else {
            return (
                <div className='Bus150520yySwitJump pyL'>
                    <Title data={title} />
                    <div className='mtL'>
                        <Rasterization>
                            <Swiper {...setting}>
                                {
                                    CaseDate.map((item, index) => {
                                        return <SwiperSlide key={index}>
                                            <Link to={{ pathname: `/CasesDetail/${item.id}`, state: { Breadcrumbs: data.Breadcrumbs } }}>
                                                <div className='item h100 '>
                                                    <img className='w100 h60' src={window.LOCALITYURL + item.attributes.img.data[0].attributes.url} alt="" ></img>
                                                    <span className='fontL bold block ptS color333'>{item.attributes.title}</span>
                                                    <span className='fontM pxS ptS clamp2 color666'>{item.attributes.content}</span>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                    })
                                }
                            </Swiper>
                            <div class="swiper-button-prev">
                                <img src={left} alt='left' />
                            </div>
                            <div class="swiper-button-next">
                                <img src={right} alt='right' />
                            </div>
                        </Rasterization>
                    </div>
                </div >
            )
        }
    }
}
