// 加入我们-立即申请入驻
import React, { Component } from 'react'
import Title from '../../reuseComponent/Title';
import VCode from '../../reuseComponent/VCode/index'
import Rasterization from '../../tool/Rasterization';
import { Input } from 'antd'
import Buttons from '../../reuseComponent/Buttons/index'
import { message, Modal } from 'antd';
import { postForm, sendEmail } from '../../API/agentset';
import './style.scss'

export default class ContUs616001ynMail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                email: "",
                company: "",
                name: "",
                post: "",
                phone: "",
                authCode: ""
            }
        }
    }
    countDown = () => {
        Modal.success({
            title: '提示',
            centered: true,
            content: (
                <div>
                    <p>您的申请已提交，稍后客服人员将联系您！</p>
                </div>
            ),
            onOk() {
                window.location.reload();
            },
        });
    }
    getInputValue = (event) => {
        let name = (event.currentTarget.getAttribute('name'));
        let value = event.target.value.replace(/\s*/g, "");
        let data = this.state.data;
        data[name] = value;
    }
    incident = () => {
        let data = {
            "data": { ...this.state.data },
        }
        for (const key in data.data) {
            if (key === 'name') {
                let myreg = /^[\u0391-\uFFE5a-zA-Z·&\\s]+$/;
                if (!myreg.test(data.data[key]) || !data.data[key]) {
                    message.error('请正确填写您的姓名');
                    return;
                }
            }
            else if (key === 'email') {
                let myreg = /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+(com|cn|net|org)$/;
                if (!myreg.test(data.data[key]) || !data.data[key]) {
                    message.error('请正确填写您的邮箱');
                    return;
                }
            }
            else if (key === 'phone') {
                let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if (!myreg.test(data.data[key]) || !data.data[key]) {
                    message.error('请正确填写您的电话');
                    return;
                }
            }
            else if (key === 'company' && !data.data[key]) {
                message.error('请填写公司');
                return;
            }
            else if (key === 'post' && !data.data[key]) {
                message.error('请填写职务');
                return;
            }
            else if (key === 'authCode') {
                let sussCode = JSON.parse(sessionStorage.getItem('code'));
                let valueCode = data.data[key].split('');

                const arr1 = sussCode.map(e => e.toLowerCase()).sort();
                const arr2 = valueCode.map(e => e.toLowerCase()).sort();

                let result = arr1.every((item, index) => {
                    return item === arr2[index]
                })
                if (!result) {
                    message.error('验证码错误！');
                    return;
                }
            } else if (!data.data[key]) {
                message.error('您还有选项未填写');
                return;
            }
        }
        postForm(data).then((res) => {
            if (res.data) {
                sendEmail({
                    "to": localStorage.getItem('email'),
                    "text": `来自蓝正官网的入驻申请：<br /> 
                用户邮箱：${data.data.email}<br /> 
                用户姓名：${data.data.name}<br />
                公司：${data.data.company}<br /> 
                职务：${data.data.post}
                用户电话：${data.data.phone}<br /> `
                }).then(element => {
                })
                this.countDown();
            }
        })
    }
    render() {
        let data = this.props.data
        let title = {
            name: data.title,
            content: '',
            titleStyle: {
                color: "#000",
                textAlign: "center"
            }
        }
        let btn = {
            text: data.button,
            style: {
                color: "#fff",
                background: " #4170D0",
                borderRadius: "0.1rem",
                borderColor: "#305DF4",
                padding: ".1rem .6rem",
                textAlign: "center",
                fontSize: ".24rem"
            }
        }
        return (
            <div className='ContUs616001ynMail pyL'>
                <Title data={title} />
                <Rasterization>
                    <div className='justifyCenter pyS'>
                        <p className='fontL'>{data.abstract}</p>
                    </div>
                    <form>
                        {
                            data.data && data.data.map((item, index) => {
                                return <div className="mbM" key={index}>
                                    {
                                        item.name === 'email' ?
                                            <Input placeholder={item.placeholder} maxLength={50} name={item.name} onChange={this.getInputValue} />
                                            : item.name === 'company' ?
                                                <Input placeholder={item.placeholder} maxLength={30} name={item.name} onChange={this.getInputValue} />
                                                : item.name === 'name' ?
                                                    <Input placeholder={item.placeholder} maxLength={20} name={item.name} onChange={this.getInputValue} />
                                                    : item.name === 'post' ?
                                                        <Input placeholder={item.placeholder} maxLength={20} name={item.name} onChange={this.getInputValue} />
                                                        : item.name === 'phone' ?
                                                            <Input placeholder={item.placeholder} maxLength={20} name={item.name} onChange={this.getInputValue} />
                                                            : item.name === 'authCode' ?
                                                                <div className="mbM alignCenter">
                                                                    <Input placeholder={item.placeholder} allowClear name={item.name} onChange={this.getInputValue} />
                                                                    <VCode />
                                                                </div> : <></>
                                    }
                                </div>
                            })
                        }
                        <div className="ptM justifyCenter">
                            <Buttons data={btn} onClick={this.incident} />
                        </div>
                    </form>
                </Rasterization>
            </div>
        )
    }
}
