import {
    SEVERSTATUS,
    DEPLOYMENTTIPS,
    CUSTOMERSTATUS,
    BTNLOADING
} from './constants';

// 修改条款内容
export const serviceStatusFn = (status, data) => {
    return { type: SEVERSTATUS, status: status, data: data };
};
// 修改部署步骤提示文字
export const setDeploymentTips = (data) => {
    return { type: DEPLOYMENTTIPS, data: data };
};
// 修改客服弹窗状态
export const setCustomerStatus = (data) => {
    return { type: CUSTOMERSTATUS, data: data };
};
// 修改按钮的loading状态
export const setBtnLoadingStatus = (status) => {
    return { type: BTNLOADING, status: status };
};