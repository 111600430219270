// 首页-成熟专业的解决方案
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Buttons from '../../reuseComponent/Buttons'
import Title from '../../reuseComponent/Title/index'
import Rasterization from '../../tool/Rasterization'
import './style.scss'
export default class Busi530616ynSwitJump extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showIndex: 0
        }
    }
    changeIndex = (index) => {
        this.setState({
            showIndex: index
        })
    }

    render() {
        let data = this.props.data
        let title = {
            name: data.title,
            content: '',
            titleStyle: {
                color: "#000",
                textAlign: "center"
            }
        }
        let btn = {
            text: data.button + '>',
            style: {
                color: "#3470F2",
                fontSize: ".24rem",
                padding: 0,
            },
        }
        return (
            <div className='Busi530616ynSwitJump bgFA pyL'>
                <Title data={title} />
                <Rasterization>
                    <div className='bgWhite border mtL pyS solutionBox flex'>
                        <div className='left'>
                            {
                                data.data.map((item, index) => {
                                    return <div key={index} className={`${index > 0 ? 'borderT' : ''} ${index === this.state.showIndex ? 'colorHigh' : ''} mxS flexAllCenter h20 fontXl solutionTitle pointer`} onClick={() => this.changeIndex(index)}>
                                        <nobr>
                                            {item.sid.data.attributes.name} {'>'}
                                        </nobr>
                                    </div>
                                })
                            }
                        </div>

                        <div className='borderL flexAllCenter'>
                            {
                                data.data.map((item, index) => {
                                    return <div key={index} className='solutionCon mxL' style={{ display: index === this.state.showIndex ? '' : 'none' }}>
                                        <div className='spaceBetween'>
                                            <div className='w65'>
                                                <p className='fontXxl bold'>{item.sid.data.attributes.sid.data[0].attributes.title}</p>

                                                <span className='fontL pbL'>{item.sid.data.attributes.sid.data[0].attributes.content}</span>

                                                <div>
                                                    <Link to={{ pathname: `/SolutionDetail/${item.sid.data.attributes.sid.data[0].id}`, state: { Breadcrumbs: data.Breadcrumbs } }}>
                                                        <Buttons data={btn}></Buttons>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className='solutionImg w30'>
                                                <img className='h100 w100' src={window.LOCALITYURL + item.sid.data.attributes.sid.data[0].attributes.img.data[0].attributes.url} alt=''></img>
                                            </div>
                                        </div>

                                        <div className='flex pyS borderB'>
                                            <span className='fontL'>{item.sid.data.attributes.sid.data[0].attributes.relatedText}：</span>
                                            {
                                                item.sid.data.attributes.sid.data[0].attributes.related.map((i, index) => {
                                                    return <div key={index} className='fontL mrS colorHigh'>
                                                        <Link to={i.path} className='colorHigh'>
                                                            {i.name}
                                                        </Link>
                                                    </div>
                                                })
                                            }
                                        </div>

                                        <div className='borderB pyS'>
                                            <span className='fontL'>{item.sid.data.attributes.sid.data[0].attributes.caseText}</span>
                                        </div>

                                        <div className='flex ptS'>
                                            {
                                                item.sid.data.attributes.sid.data[0].attributes.case.map((i, index) => {
                                                    return <div key={index} className='caseImg flexAllCenter'>
                                                        {
                                                            i.img.data ?
                                                                <img className='w100 mrL' src={window.LOCALITYURL + i.img.data.attributes.url} alt=''></img>
                                                                :
                                                                <span>暂无数据</span>
                                                        }
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </Rasterization >
            </div >
        )
    }
}
