//栅格化公共组件
import { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'antd'

class Rasterization extends Component {
    render() {
        return (
            <Row className="w100">
                <Col xs={2} sm={2} md={2} lg={2} xl={4} xxl={4}></Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={16} xxl={16}>
                    {this.props.children}
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={4} xxl={4}></Col>
            </Row>
        );
    }
}

export default withRouter(Rasterization);