//拦截器
import axios from 'axios'
import { message } from 'antd';

axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
// window.LOCALITYURL = "http://192.168.0.11:1337";//本地服务器
window.LOCALITYURL = "http://test7.blueking.net.cn/lz";//线上服务器

const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分 
    baseURL: window.LOCALITYURL,
    // 超时
    timeout: 20000
})
// request拦截器
service.interceptors.request.use(

    config => {
        // if (getToken()) {
        //     config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
        // }
        return config
    },
    error => {
        Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(res => {
    // const code = res.data.code
    return res.data
    // if (code === 0) {
    //     console.log('成功', res.data)
    //     return res.data
    // } else {
    //     // message.error(res.data.errMsg);
    //     return Promise.reject(res.data)
    // }
}, error => {
    if (error.message === 'Network Error') {
        message.error('服务器错误');
        return Promise.reject(error)
    } else {
        message.error('服务器错误');
        return Promise.reject(error)
    }
}
)

export default service
