//公司官网 底部导航栏
import { Component } from 'react';
import Rasterization from '../../tool/Rasterization';
import { Link } from 'react-router-dom'
import './style.scss'
import { withRouter } from 'react-router-dom';

class Bott460100ynJump extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        let data = this.props.data;
        return (
            <div className="base pyL">
                <Rasterization>
                    <div className="w100 baseContent flexWrap">
                        {
                            data && data.map((item, index) => {
                                return <div key={index} className='item'>
                                    <p className="white fontL pbS">{item.name}</p>
                                    {
                                        item.data && item.data.map((i, n) => {
                                            return <div key={n} className='alignStart'>
                                                {
                                                    i.img.data ?
                                                        <img className='mrS' src={window.LOCALITYURL + i.img.data.attributes.url} alt=''></img>
                                                        :
                                                        <></>
                                                }
                                                <Link to={i.path}>
                                                    <span className="fontM baseText pbS pointer">{i.name}</span>
                                                </Link>
                                            </div>
                                        })
                                    }
                                </div>
                            })
                        }
                    </div>
                    {
                        this.props.children
                    }
                </Rasterization>
            </div>
        );
    }
}

export default withRouter(Bott460100ynJump);
