import React, { Component } from 'react'
import Title from '../../reuseComponent/Title'
import Rasterization from '../../tool/Rasterization'
import './style.scss'

export default class Busi240060ynSwit extends Component {
    render() {
        let data = this.props.data
        let title = {
            name: data.title,
            content: data.abstract,
            titleStyle: {
                color: "#000",
                textAlign: "center"
            }
        }
        return (
            <div className='Busi240060ynSwit'>
                <Title data={title} />
                <Rasterization>
                    <div className='ptL flex w100 h100'>
                        <div className='bgE9EAEE w20 pM'>
                            <span className='fontXxl w50'>{data.textTitle}</span>
                            <span className='block fontL bold pyS'>|</span>
                            <span className='fontL bold'>{data.textTitle2}</span>
                            <span className='fontM pyM'>{data.content}</span>
                        </div>
                        <div className='w80 flexWrap'>
                            {
                                data.data.map((item, index) => {
                                    return <div key={index} className='item w33 flexAllCenter flexColumn textCenter'>
                                        <div className='content'>
                                            <img src={window.LOCALITYURL + item.img.data[0].attributes.url} alt=''></img>
                                            <p>{item.title}</p>
                                            <span>{item.abstract}</span>
                                        </div>

                                        <div className='active flexAllCenter'>
                                            <span className='w70'>{item.content}</span>
                                        </div>
                                    </div>
                                })
                            }
                        </div>

                    </div>
                </Rasterization>
            </div>
        )
    }
}
