// 产品-GPU-产品功能
import React, { Component } from 'react'
import Title from '../../reuseComponent/Title'
import Rasterization from '../../tool/Rasterization'
import './style.scss'

export default class Busi130030yn extends Component {
    render() {
        let data = this.props.data
        let title = {
            name: data.title,
            content: data.abstract,
            titleStyle: {
                color: "#000",
                textAlign: "center"
            },
            abstractStyle: {
                color: "#F0F0F0",
                textAlign: "center"
            }
        }
        return (
            <>
                {
                    data.style === '1' ?
                        <div className='Busi130030yn pyL'>
                            <Title data={title} />
                            <Rasterization>
                                <div className='spaceBetween ptL '>
                                    {
                                        data.data.map((item, index) => {
                                            return <div key={index} className='w30 flexAllCenter flexColumn textCenter item pL'>
                                                <img src={window.LOCALITYURL + item.img.data[0].attributes.url} alt=''></img>
                                                <p className='fontL'>{item.title}</p>
                                                <span className='w80 fontM'>{item.abstract}</span>
                                            </div>
                                        })
                                    }
                                </div>
                            </Rasterization>
                        </div>
                        :
                        <div className='Busi130030yn pyL'>
                            <Title data={title} />
                            <Rasterization>
                                <div className='spaceBetween ptL '>
                                    {
                                        data.data.map((item, index) => {
                                            return <div key={index} className='w33 flexAllCenter flexColumn textCenter item2 pM'>
                                                <img src={window.LOCALITYURL + item.img.data[0].attributes.url} alt=''></img>
                                                <p className='fontL'>{item.title}</p>
                                                <span className='w80 fontM'>{item.abstract}</span>
                                            </div>
                                        })
                                    }
                                </div>
                            </Rasterization>
                        </div>
                }
            </>
        )
    }
}
