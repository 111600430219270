// 关于我们-工作机会
import React, { Component } from 'react'
import Title from '../../reuseComponent/Title';
import Rasterization from '../../tool/Rasterization';
import Buttons from '../../reuseComponent/Buttons'
import { Tabs } from 'antd';
import './style.scss'

const { TabPane } = Tabs;

export default class Hist610020yySwit extends Component {
    incident = (url) => {
        let w = window.open('_blank')
        w.window.location.href = url
    }
    render() {
        let data = this.props.data;
        let title = {
            name: data.title,
            content: data.abstract,
            titleStyle: {
                color: "#000",
                textAlign: "center"
            }
        }
        let btn = {
            text: data.button,
            style: {
                color: "#4170D0",
                background: "",
                borderColor: "#fff",
                padding: " 0.05rem .1rem",
                fontSize: ".24rem",
                margin: ".2rem 0"
            },
        }
        return (
            <div className='Hist610020yySwit pyL'>
                <Title data={title} />
                <Rasterization>
                    <div className='ptL'>
                        <Tabs defaultActiveKey="0">
                            {
                                data.data.map((item, index) => {
                                    return <TabPane tab={item.title} key={index}>
                                        {
                                            item.data.map((i, key) => {
                                                return <div key={key} className='alignCenter spaceBetween mbS tabItem'>
                                                    <div className='w10 pL bgBlue textCenter'>
                                                        <span className='fontXxl white'><nobr>{i.address}</nobr></span>
                                                    </div>
                                                    <div className='spaceBetween alignCenter w90 pxS'>
                                                        <div>
                                                            <span className='fontL'>{i.post}</span>
                                                        </div>
                                                        <Buttons data={btn} onClick={() => this.incident(i.url)} ></Buttons>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </TabPane>
                                })
                            }
                        </Tabs>
                    </div>
                </Rasterization>
            </div>
        )
    }
}
