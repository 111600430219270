// 案例详情
import React, { Component } from 'react'
import './style.scss'
import Rasterization from '../../tool/Rasterization'
import { withRouter } from 'react-router-dom';
import { getCaseDetail, getPrevNews, getNextNews } from '../../API/agentset';
import Loading from '../../reuseComponent/Loading/index';
import Breadcrumbs from '../../reuseComponent/Breadcrumbs/index'

class Case510202yySwit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            isLoading: true,
            next: [],
            prev: [],
        }
    }
    componentWillReceiveProps(newProps) {
        const id = newProps.match.params.id;
        this.getList(id);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.getList(id);

        let breadcrumbsData = []
        if (this.props.location.state) {
            this.props.location.state.Breadcrumbs.forEach(element => {
                breadcrumbsData.push(element)
            });
            sessionStorage.setItem('Breadcrumbs', JSON.stringify(breadcrumbsData))
        }
    }
    componentWillUnmount() {
        sessionStorage.removeItem('Breadcrumbs');
    }
    //上一篇
    getPrevNewsList = (id) => {
        let data = {
            "populate[0]": "img,caseType",
            "sort": "id:desc",
            "filters[id][$lt]": id,
            // "locale": localStorage.getItem('language')
        }
        getPrevNews(data).then((res) => {
            this.setState({
                prev: res.data
            })
        })
    }
    //下一篇
    getNextNewsList = (id) => {
        let data = {
            "populate[0]": "img,caseType",
            "filters[id][$gt]": id,
            // "locale": localStorage.getItem('language')
        }
        getNextNews(data).then((res) => {
            this.setState({
                next: res.data
            })
        })
    }
    getList = (id) => {
        this.setState({
            isLoading: true
        })
        getCaseDetail({ id: id }).then((res) => {
            let data = res.data.attributes;
            if (data) {
                this.setState({
                    data: data,
                    isLoading: false,
                })
                // let lang = localStorage.getItem('language');
                // if (lang === data.locale) {
                //     return
                // } else {
                //     data.localizations.data.forEach((item, index) => {
                //         if (item.attributes.locale === lang) {
                //             this.jumpFn(item.id)
                //         }
                //     })
                // }
            }
        })
        this.getPrevNewsList(id)
        this.getNextNewsList(id)
    }
    jumpFn = (id) => {
        this.props.history.replace(`/CasesDetail/${id}`)
    }
    render() {
        let { isLoading } = this.state;

        let breadcrumbs = JSON.parse(sessionStorage.getItem("Breadcrumbs"))

        if (isLoading) {
            return <Loading />
        } else {
            let { data, next, prev } = this.state;
            return (
                <div className="Case510202yySwit myL">
                    <Rasterization>
                        <Breadcrumbs data={breadcrumbs} />

                        <div className="borderB pbM mbM">
                            <div className="myM">
                                <p className="textCenter fontXxl mbM">{data.title}</p>
                                <div className="textCenter fontL color999">
                                    <span>{data.date}</span>
                                </div>
                            </div>
                            <div className="flexAllCenter flexColumn">
                                <div className="preWrap fontM" dangerouslySetInnerHTML={{ __html: data.content }}></div>
                                <div className='ptL caseImg'>
                                    <img className='w100 h100' src={window.LOCALITYURL + data.img.data[0].attributes.url} alt="" ></img>
                                </div>
                            </div>
                        </div>

                        {
                            (prev || next) && <div className="alignCenter spaceBetween fontL">
                                <div className="mrL pointer color999">
                                    <button className="mrS chapter" disabled={prev.length <= 0 ? true : false} onClick={() => { this.jumpFn(prev[0].id) }}>
                                        {this.props.data.previous}:  {prev.length <= 0 ? this.props.data.state : prev[0].attributes.title}
                                    </button>
                                </div>
                                <div className={`"pointer ${prev.length < 0 ? 'color999' : 'colorRed'}`}>
                                    <button className="mrS chapter" disabled={next.length <= 0 ? true : false} onClick={() => { this.jumpFn(next[0].id) }}>
                                        {this.props.data.next}:   {next.length <= 0 ? this.props.data.state : next[0].attributes.title}
                                    </button>
                                </div>
                            </div>
                        }
                    </Rasterization >
                </div >
            );
        }
    }
}

export default withRouter(Case510202yySwit);
