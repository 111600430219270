import React, { Component } from 'react'
import Rasterization from '../../tool/Rasterization'
import { Link, withRouter } from 'react-router-dom';
import Buttons from '../../reuseComponent/Buttons/index'
import './style.scss'
import { getSolution } from '../../API/agentset';
import Loading from '../../reuseComponent/Loading';
import icon from '../../images/icon3.png'
import { throttle } from '../../tool/throttle';
import { message } from 'antd';
class Case220202ynJump extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 1,
            SolutionData: [],
            isLoading: true,

            page: 1,
            pageSize: 3,
            total: true,
        }
    }
    componentDidMount() {
        let id = this.props.match.params.id * 1
        this.setState({
            id: id
        })
        this.getList(id, this.state.page, this.state.pageSize);
    }
    getList = (id, page, pageSize) => {
        let data = {
            "populate": "img",
            "sort[0]": "createdAt:desc",
            "filters[key][$eq]": id,
            "pagination[page]": page,
            "pagination[pageSize]": pageSize
        }
        getSolution(data).then(res => {
            if (res.data.length > 0) {
                let SolutionData = this.state.SolutionData;
                this.setState({
                    SolutionData: [...SolutionData, ...res.data],
                    isLoading: false,
                })
            } else {
                this.setState({
                    total: false
                })
                message.info({
                    content: '没有更多了!'
                })
            }
        })
    }
    //路由改变触发
    UNSAFE_componentWillReceiveProps(nextProps) {
        // 判断跳转路由不等于当前路由
        if (nextProps.location.pathname !== this.props.location.pathname) {
            window.location.reload();
        }
    }
    switch = (id) => {
        this.props.history.replace(`/Solution/${id}`)
    }
    showCase = throttle(() => {
        let page = this.state.page + 1;
        let id = this.state.id;
        this.setState(state => ({
            page: page,
        }), () => {
            this.getList(id, this.state.page, this.state.pageSize);
        });
    }, 1000, true)

    render() {
        let data = this.props.data
        let { id, isLoading, SolutionData, total } = this.state
        let btn = {
            text: data.button,
            style: {
                color: "#fff",
                background: "#4170D0",
                borderRadius: ".4rem",
                fontSize: ".24rem",
                padding: ".1rem .5rem"
            },
        }
        let btn2 = {
            text: data.button,
            style: {
                color: "#fff",
                background: total ? "#4170D0" : "#999999",
                borderRadius: ".4rem",
                fontSize: ".24rem",
                padding: ".1rem .5rem"
            },
        }
        if (isLoading) {
            return <Loading />
        } else {
            return (
                <div className='Case220202ynJump pyL'>
                    <Rasterization>
                        <div className='flexAllCenter textCenter mbL'>
                            <span className={`title text fontL pointer mrM ${id === 1 ? 'active' : 'activeHover'}`} onClick={() => this.switch(1)}>{data.title}</span>
                            <span className={`title text2 fontL pointer ${id === 2 ? 'active' : 'activeHover'}`} onClick={() => this.switch(2)}>{data.title2}</span>
                        </div>
                        {
                            SolutionData.map((item, index) => {
                                return <div key={index} className='item spaceBetween'>
                                    {
                                        item.attributes.img.data.length >= 2 ?
                                            <div className='itemLeft h100'>
                                                <img src={window.LOCALITYURL + item.attributes.img.data[0].attributes.url} alt=''></img>
                                                <img className='itemLeftImg' src={window.LOCALITYURL + item.attributes.img.data[1].attributes.url} alt=''></img>
                                            </div>
                                            :
                                            <div className='itemLeft flexAllCenter h100'>
                                                <img className='w100' src={window.LOCALITYURL + item.attributes.img.data[0].attributes.url} alt=''></img>
                                            </div>
                                    }
                                    <div className='w50 h100 justifyCenter flexColumn'>
                                        <p className='fontXxl fontWeight600'>{item.attributes.title}</p>

                                        <span className='horizontalBar'></span>

                                        <span className='fontL w90 pyM'>{item.attributes.content}</span>
                                        <div>
                                            <Link to={{ pathname: `/SolutionDetail/${item.id}`, state: { Breadcrumbs: data.Breadcrumbs } }}>
                                                <Buttons data={btn} />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                        <div className="ptM justifyCenter">
                            <Buttons data={btn2} icon={icon} onClick={() => { this.showCase() }} iconDeg={90} />
                        </div>
                    </Rasterization>
                </div>
            )
        }
    }
}
export default withRouter(Case220202ynJump)