// 产品-运维-精选案例
import React, { Component } from 'react'
import Title from '../../reuseComponent/Title'
import { Carousel } from 'antd'
import left from '../../images/Group 1006.png'
import right from '../../images/Group 1005.png'
import './style.scss'
import { Link, withRouter } from 'react-router-dom'
class Case130320yySwitJump extends Component {
    constructor(props) {
        super(props)
        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
        this.state = {
            CaseData: '',
        }
    }
    componentDidMount() {
    }
    next() {
        this.slider.innerSlider.slickNext()
    }
    prev() {
        this.slider.innerSlider.slickPrev();
    }
    render() {
        let data = this.props.data
        let title = {
            name: data.title,
            content: '',
            titleStyle: {
                color: "#000",
                textAlign: "center"
            }
        }
        const lunboSetting = {
            dots: false,
            lazyLoad: true,
            autoplay: false,
            infinite: true,
        };
        return (
            <div className='Case130320yySwitJump pyL'>
                <Title data={title} />
                <div className='spaceBetween w80 m0Auto ptL'>
                    <div className='flexAllCenter mrM'>
                        <img src={left} alt='' className='cutoverImg' style={{ width: "0.8rem", height: "0.8rem" }} onClick={this.prev}></img>
                    </div>

                    <Carousel {...lunboSetting} ref={el => { this.slider = el }} slidesToShow={3}>
                        {
                            data.data.map((item, index) => {
                                return <div key={index} className='item'>
                                    <Link to={{ pathname: `/CasesDetail/${item.sid.data.id}`, state: { Breadcrumbs: data.Breadcrumbs } }}>
                                        <div className='productImg relative'>
                                            <img className='w100 h100' src={window.LOCALITYURL + item.sid.data.attributes.img.data[0].attributes.url} alt="" ></img>

                                            <div className='hoverCon bgWhite absolute pL'>
                                                <p className='fontL bold color333'>{item.sid.data.attributes.date}</p>
                                                <span className='fontS clamp4 color666'>{item.sid.data.attributes.content}</span>
                                            </div>
                                        </div>
                                        <div className='ptM'>
                                            <span className='fontL bold color333'>{item.sid.data.attributes.title}</span>
                                            <span className='fontM clamp1 color666'>{item.sid.data.attributes.content}</span>
                                        </div>
                                    </Link>
                                </div>
                            })
                        }
                    </Carousel>

                    <div className='flexAllCenter mlM'>
                        <img src={right} alt='' className='cutoverImg' style={{ width: "0.8rem", height: "0.8rem" }} onClick={this.next} ></img>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(Case130320yySwitJump)