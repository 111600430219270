// 首页-我们的优势
import { Col, Row } from 'antd'
import React, { Component } from 'react'
import Title from '../../reuseComponent/Title'
import Rasterization from '../../tool/Rasterization'

export default class Busi220040yn extends Component {
    render() {
        let data = this.props.data
        let title = {
            name: data.title,
            content: data.abstract,
            titleStyle: {
                color: "#000",
                textAlign: "center"
            },
            abstractStyle: {
                color: "#F0F0F0",
                textAlign: "center"
            }
        }
        return (
            <div className='Busi220040yn pyL' >
                <Title data={title} />
                <Rasterization>
                    <Row gutter={[16, 16]} className='ptL'>
                        {
                            data.data.map((item, index) => {
                                return <Col span={12} key={index}>
                                    <div className='bgEDF07F7 flexAllCenter pS h100'>
                                        <div className='w20 prS flexAllCenter'>
                                            <img className='' src={window.LOCALITYURL + item.img.data[0].attributes.url} alt=''></img>
                                        </div>

                                        <div className='borderL plS w80'>
                                            <p className='fontL bold'>{item.title}</p>
                                            <span className='fontM'>{item.abstract}</span>
                                        </div>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Rasterization>
            </div>
        )
    }
}
