//面包屑组件
import { Component } from 'react';
import './style.scss'
import { withRouter } from 'react-router-dom';

class Breadcrumbs extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
    }

    render() {
        let data = this.props.data;
        data.forEach((item, index) => {
            if (index > 0) {
                item.name = " ＞ " + item.name
            }
        })
        return (
            <div className="bar plS fontL">
                {
                    data && data.map((item, index) => {
                        return <span key={index}>
                            <a className={`${item.present ? 'colorHigh' : 'color666'}`} href={item.path ? item.path : ''}>{item.name}</a>
                        </span>
                    })
                }
            </div>
        );
    }
}

export default withRouter(Breadcrumbs);
