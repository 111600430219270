//门户 客服组件
import { Component } from 'react';
import './style.scss'
import { Input } from 'antd';
import cancel from '../../images/关  闭.png'
import Buttons from '../../reuseComponent/Buttons';
import Logo from '../../images/logo1.png'
import { Modal } from 'antd';
import lisa from '../../images/lisa.png'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setCustomerStatus } from '../../store/action';

class CustomerService411211yy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ""
        }
    }
    componentDidMount() {
    }
    incident = () => {

    }
    onChange = (e) => {
        let value = e.target.value;
        this.setState({
            value: value
        })
    }
    render() {
        const { TextArea } = Input;
        let btn = {
            // text: "发送",
            text: this.props.data[0].button,
            style: {
                color: "#fff",
                background: "#305DF4",
                borderRadius: ".05rem",
                borderColor: "#305DF4",
                // width: "1.6rem",
                padding: ".15rem 0.4rem",
                margin: "0 .4rem"
            },
        }
        return (
            <div className="CustomerService411211yy w100 h100">
                <Modal title="" wrapClassName="CustomerService411211yy" closable={false} centered width={'50%'} footer={null} visible={this.props.customer} onCancel={() => { this.props.increment(false) }}>
                    <div className="relative CustomerService411211yyContent">
                        <div className="absolute tl0 w100 bgBlue pxM spaceBetween alignCenter top">
                            <img className="logo" src={Logo} alt="" />
                            {/* 在线客服 */}
                            <span className="absolute fontXxl white title textCenter"><nobr>{this.props.data[0].title}</nobr></span>
                            <img className="cancel pointer" src={cancel} alt="" onClick={() => { this.props.increment(false) }} />
                        </div>
                        <div className="content">
                            <div className="flex pM">
                                <img className='lisa mrM' src={lisa} alt='' />
                                <div className='textLeft fontL'>
                                    <p className='color666'>Lisa 15:53:12</p>
                                    {/* <p className='radius lisaText pM w70'> 你好，我是蓝正的Lisa,有问题可以随时联系我18702530437（微信同号），希望我们可以帮助您快速搭建自己的网站。</p> */}
                                    <p className='radius lisaText pM w70'>{this.props.data[0].context}</p>
                                </div>
                            </div>
                        </div>
                        <div className="foot shadows spaceBetween flexColumn pyM">
                            <TextArea autoSize={{ minRows: 4, maxRows: 4 }} bordered={false} placeholder={this.props.data[0].placeholder} style={{ height: 120 }} onChange={this.onChange} />
                            <div className="contentEnd w100">
                                <Buttons data={btn} onClick={() => { this.props.increment(false) }} />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        increment(data) {
            dispatch(setCustomerStatus(data));
        },
    }
}
const mapStateToProps = (state) => {
    return {
        customer: state.customer,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerService411211yy));
