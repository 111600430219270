import React, { Component } from 'react'
import Title from '../../reuseComponent/Title'
import Rasterization from '../../tool/Rasterization'
import Buttons from '../../reuseComponent/Buttons/index'
import yes from '../../images/yes.png'
import './style.scss'

export default class ProdShow130013ynBuy extends Component {
    render() {
        let data = this.props.data
        let title = {
            name: data.title,
            content: data.abstract,
            titleStyle: {
                color: "#000",
                textAlign: "center"
            },
            abstractStyle: {
                color: "#F0F0F0",
                textAlign: "center"
            }
        }
        let btn = {
            text: data.button,
            style: {
                color: "#fff",
                background: "#305DF4",
                width: "100%",
                textAlign: "center",
                fontSize: ".24rem"
            }
        }
        return (
            <div className='ProdShow130013ynBuy pyL'>
                <Title data={title} />
                <Rasterization>
                    <div className='spaceBetween ptL'>
                        {
                            data.data.map((item, index) => {
                                return <div key={index} className='item w30 spaceBetween flexColumn'>
                                    <div className='title pS'>
                                        <span className='fontXxl white block clamp1'>{item.title}</span>
                                        <span className='whiteBlock'></span>
                                        <span className='whiteBlock'></span>
                                        <span className='whiteBlock'></span>
                                    </div>

                                    <div className='pS spaceBetween flexColumn'>
                                        <div className='text'>
                                            {
                                                item.data.map((i, index) => {
                                                    return <div key={index} className='pbS alignCenter'>
                                                        <img src={yes} alt=''></img>
                                                        <span className='fontL mlS color666'>{i.text}</span>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div className='bgF3 pS'>
                                            <span className='dot bg0656AD'></span>
                                            <span className='mlS fontM colorRed'>{item.tips}</span>
                                        </div>
                                    </div>

                                    <div className='itemBottom spaceBetween'>
                                        <div className='w80 pS alignCenter'>
                                            <span className='white fontL mrS'>{item.price}</span>
                                            {
                                                item.priceNum.split('').map((i, index) => {
                                                    return <span key={index} className='priceNum fontXl white textCenter'>{i}</span>
                                                })
                                            }
                                            <span className='originalPrice fontM mlS'>{item.originalPrice}
                                                <br />
                                                <span className='originalPriceNum fontM'>{item.originalPriceNum}</span>
                                            </span>
                                        </div>
                                        <div className='btn w20 flexAllCenter'>
                                            <Buttons data={btn} />
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </Rasterization>
            </div>
        )
    }
}
