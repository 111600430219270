//客服浮标
import { Component } from 'react';
import './style.scss'
import { withRouter } from 'react-router-dom';
import img from '../../images/客服分析 耳麦 客服.png'
import { connect } from 'react-redux';
import { setCustomerStatus } from '../../store/action';
class Float extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className='FloatBox' >
                <div className='FloatCon bgBlue pointer pS alignCenter flexColumn' onClick={() => { this.props.increment(true) }}>
                    <img src={img} alt="" className='icon' />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        increment(data) {
            dispatch(setCustomerStatus(data));
        },
    }
}
const mapStateToProps = (state) => {
    return {
        customer: state.customer,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Float));