//加载中
import React, { Component } from 'react'
import './style.scss'
import { Spin } from 'antd';
class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {

    }
    render() {
        return (
            <div className="loading flexAllCenter h100vh fixed tl0 w100">
                <Spin tip="努力加载中..." size="large"></Spin>
            </div>
        )
    }
}
export default Loading;