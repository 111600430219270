// 产品-软件开发-套餐版本组件
import { Component } from 'react';
import './style.scss'
import { withRouter } from 'react-router-dom';
import Buttons from '../../reuseComponent/Buttons/index'
import Title from '../../reuseComponent/Title';
import Rasterization from '../../tool/Rasterization'

class Buy150505ynJump extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
    }
    incident = () => {
        let w = window.open('_blank')
        w.window.location.href = 'http://wbs.shlzit.com/Portal/PortalSetMeal'
    }
    render() {
        let data = this.props.data;
        let btn = {
            text: data.button,
            style: {
                color: "#fff",
                borderRadius: ".4rem",
                borderColor: "",
                padding: ".1rem .5rem",
                margin: "0.2rem auto",
                fontSize: ".22rem"
            },
        }
        let title = {
            name: data.title,
            content: '',
            titleStyle: {
                color: "#000",
                textAlign: "center"
            }
        }
        return (
            <div className="Buy150505ynJump w100 myL">
                <Title data={title} />
                <Rasterization>
                    <div className="flex h100 ptL">
                        {
                            data.data && data.data.map((item, index) => {
                                return <div key={index} className="item flex1 mrS radius h100 spaceBetween flexColumn">
                                    <div style={{ backgroundImage: `url('${window.LOCALITYURL + item.img.data.attributes.url}')`, backgroundSize: "100% 100%", height: "3.55rem" }} className="textCenter bgCenter noRepeat pyM spaceBetween alignCenter flexColumn">
                                        <span className="fontXl white fontWeight600 clamp1">{item.title}</span>
                                        <span className="fontM white pyS">{item.abstract}</span>
                                        <span className="fontXl white">{item.price}</span>
                                    </div>
                                    <div className="itemBottom h100 spaceBetween flexColumn pM bgWhite">
                                        <div>
                                            {
                                                item.content.split(',').map((i, n) => {
                                                    return <span className="color999 fontL pbS alignCenter" key={n}><i className="dot bg999 mrS"></i>{i}</span>
                                                })
                                            }
                                        </div>
                                        <Buttons data={btn} onClick={() => { this.incident() }} />
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </Rasterization>
            </div>
        );
    }
}

export default withRouter(Buy150505ynJump);
