// 首页-合作伙伴
import Title from '../../reuseComponent/Title'
import React, { Component } from 'react'
import Rasterization from '../../tool/Rasterization'
import './style.scss'

export default class Part350500nn extends Component {
    render() {
        let data = this.props.data
        let title = {
            name: data.title,
            content: data.abstract,
            titleStyle: {
                color: "#000",
                textAlign: "center"
            },
            abstractStyle: {
                color: "#F0F0F0",
                textAlign: "center"
            }
        }
        return (
            <div className='Part350500nn'>
                <Title data={title} />
                <Rasterization>
                    <div className='pyL'>
                        <div className='flexWrap partBox'>
                            {
                                data.data.map((item, index) => {
                                    return <div key={index} className='item w20 flexAllCenter'>
                                        <img className='' src={window.LOCALITYURL + item.img.data.attributes.url} alt=''></img>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </Rasterization>
            </div>
        )
    }
}
