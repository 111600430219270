// 关于我们-联系我们
import React, { Component } from 'react'
import Title from '../../reuseComponent/Title';
import Rasterization from '../../tool/Rasterization';
import { Input } from 'antd'
import Buttons from '../../reuseComponent/Buttons/index'
import './style.scss'
import { Map, Marker, NavigationControl } from 'react-bmapgl';
import { message, Modal } from 'antd';
import { postFormApplications, sendEmail } from '../../API/agentset';
export default class ContUs524141ynMail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                name: "",
                email: "",
                phone: "",
                information: ""
            }
        }
    }
    countDown = () => {
        Modal.success({
            title: '提示',
            centered: true,
            content: (
                <div>
                    <p>已提交，稍后客服人员将联系您！</p>
                </div>
            ),
            onOk() {
                window.location.reload();
            },
        });
    }
    getInputValue = (event) => {
        let name = (event.currentTarget.getAttribute('name'));
        let value = event.target.value.replace(/\s*/g, "");
        let data = this.state.data;
        data[name] = value;
    }
    incident = () => {
        let data = {
            "data": { ...this.state.data },
        }
        for (const key in data.data) {
            if (key === 'name') {
                let myreg = /^[\u0391-\uFFE5a-zA-Z·&\\s]+$/;
                if (!myreg.test(data.data[key]) || !data.data[key]) {
                    message.error('请正确填写您的姓名');
                    return;
                }
            }
            else if (key === 'email') {
                let myreg = /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+(com|cn|net|org)$/;
                if (!myreg.test(data.data[key]) || !data.data[key]) {
                    message.error('请正确填写您的邮箱');
                    return;
                }
            }
            else if (key === 'phone') {
                let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if (!myreg.test(data.data[key]) || !data.data[key]) {
                    message.error('请正确填写您的电话');
                    return;
                }
            }
            else if (key === 'information' && !data.data[key]) {
                message.error('请填写您的问题');
                return;
            }
            else if (!data.data[key]) {
                message.error('您还有选项未填写');
                return;
            }
        }
        postFormApplications(data).then((res) => {
            if (res.data) {
                sendEmail({
                    "to": localStorage.getItem('email'),
                    "text": `来自蓝正官网加入我们页面的留言信息：<br /> 
                用户姓名：${data.data.name}<br />
                用户邮箱：${data.data.email}<br /> 
                电话：${data.data.phone}<br /> 
                问题描述${data.data.information} `
                }).then(element => {
                })
                this.countDown();
            }
        })
    }
    render() {
        let data = this.props.data;
        let title = {
            name: data.title,
            content: data.abstract,
            titleStyle: {
                color: "#000",
                textAlign: "center"
            }
        }
        let btn = {
            text: data.button,
            style: {
                color: "#fff",
                background: " #4170D0",
                borderRadius: "0.1rem",
                borderColor: "#305DF4",
                width: "100%",
                padding: ".2rem 0",
                textAlign: "center",
                fontSize: ".24rem"
            }
        }

        const { TextArea } = Input;

        return (
            <div className='ContUs524141ynMail bgF789 pyL'>
                <Title data={title} />
                <Rasterization>
                    <div className='spaceBetween pyL'>
                        <div className="w50">
                            <Map style={{ height: '5rem' }} center={{ lng: 121.477001, lat: 31.236636 }} zoom="11">
                                <Marker position={{ lng: 121.362215, lat: 31.222942 }} />
                                <NavigationControl />
                            </Map>
                        </div>

                        <div className='w50'>
                            {
                                data.data.map((item, index) => {
                                    return <div key={index} className='pM'>
                                        <img src={window.LOCALITYURL + item.img.data.attributes.url} alt='' />
                                        <span className='mlS'>{item.title}</span>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                    <span className='pS bgBlue white fontL'>{data.text}</span>

                    <form className='ptL'>
                        {
                            data.input && data.input.map((item, index) => {
                                return <div className="mbM" key={index}>
                                    {
                                        item.name === 'name' ?
                                            <div className='column'>
                                                <Input placeholder={item.placeholder} maxLength={20} name={item.name} onChange={this.getInputValue} />
                                            </div>
                                            : item.name === 'email' ?
                                                <div className='column'>
                                                    <Input placeholder={item.placeholder} maxLength={50} name={item.name} onChange={this.getInputValue} />
                                                </div>
                                                : item.name === 'phone' ?
                                                    <div className='column'>
                                                        <Input placeholder={item.placeholder} maxLength={20} name={item.name} onChange={this.getInputValue} />
                                                    </div>
                                                    : item.name === 'information' ?
                                                        <div className='column'>
                                                            <TextArea placeholder={item.placeholder} showCount maxLength={999} autoSize={{ minRows: 6, maxRows: 6 }} name={item.name} onChange={this.getInputValue} />
                                                        </div>
                                                        : <></>
                                    }
                                </div>
                            })
                        }
                        <div className="ptM">
                            <Buttons data={btn} onClick={this.incident} />
                        </div>
                    </form>
                </Rasterization>
            </div>
        )
    }
}
