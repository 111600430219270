// 产品-软件开发-Banner下组件
import { Component } from 'react';
import './style.scss'
import Buttons from '../../reuseComponent/Buttons/index'
import Rasterization from '../../tool/Rasterization'
import { withRouter } from 'react-router-dom';

class Busi120101ynLogIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isIndex: null,
            isTop: 0,
            height: 0
        }
    }
    incident = () => {
        let w = window.open('_blank')
        w.window.location.href = 'http://wbs.shlzit.com'
    }
    mouseOver = (index) => {
        this.setState({
            isIndex: index
        })
    }
    mouseOut = () => {
        this.setState({
            isIndex: null
        })
    }
    render() {
        let data = this.props.data;
        let btn = {
            text: data.button,
            style: {
                color: "#fff",
                background: "#305DF4",
                borderRadius: ".4rem",
                borderColor: "#305DF4",
                fontSize: ".20rem",
                padding: ".1rem .5rem"
            },
        }
        return (
            <div className="Busi120101ynLogIn pyL bgGray">
                <Rasterization>
                    {
                        data.data && data.data.map((item, index) => {
                            return <div key={index} className="pbL item alignCenter">
                                {
                                    item.img.data && <div className="img w40" style={{ backgroundImage: `url(${window.LOCALITYURL + item.img.data[0].attributes.url})` }}></div>
                                }
                                <div className="w60 pL h100 justifyCenter flexColumn">
                                    <p className="fontXxl fontWeight600 colorHigh">{item.title}</p>
                                    <p className="fontL w90 pyM">{item.abstract}</p>
                                    <Buttons data={btn} onClick={() => { this.incident() }} />
                                </div>
                            </div>
                        })
                    }
                </Rasterization>
            </div>
        );
    }
}

export default withRouter(Busi120101ynLogIn);
