// 关于我们-企业文化
import React, { Component } from 'react'
import Title from '../../reuseComponent/Title';
import Rasterization from '../../tool/Rasterization';
import './style.scss'

export default class CorCult140000yn extends Component {
    render() {
        let data = this.props.data;
        let title = {
            name: data.title,
            content: data.abstract,
            titleStyle: {
                color: "#000",
                textAlign: "center"
            },
            abstractStyle: {
                color: '#EEEEEE',
                textAlign: "center"
            }
        }
        return (
            <div className='CorCult140000yn pyL'>
                <Title data={title} />
                <Rasterization>
                    <div className='spaceBetween ptL'>
                        {
                            data.data.map((item, index) => {
                                return <div key={index} className=''>
                                    <div className='hexagonGray flexAllCenter flexColumn'>
                                        <div className='hexagonWhite flexAllCenter flexColumn'>
                                            <div className='hexagonBlue flexAllCenter flexColumn'>
                                                <div className='white textCenter'>
                                                    <p className='fontXl'>{item.title}</p>
                                                    <p className='fontL'>{item.abstract}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='ptL'>
                                        <span className='fontXl color666'>{item.slogan}</span>
                                    </div>
                                </div>
                            })
                        }
                    </div >

                </Rasterization >
            </div >
        )
    }
}
