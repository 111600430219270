// 产品-软件服务-服务内容
import React, { Component } from 'react'
import Title from '../../reuseComponent/Title'
import Rasterization from '../../tool/Rasterization'
import './style.scss'

export default class Busi420114ynJump extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showIndex: 0
        }
    }
    changeIndex = (index) => {
        this.setState({
            showIndex: index
        })
    }
    render() {
        let data = this.props.data
        let title = {
            name: data.title,
            content: data.abstract,
            titleStyle: {
                color: "#000",
                textAlign: "center"
            },
            abstractStyle: {
                color: "#F0F0F0",
                textAlign: "center"
            }
        }
        return (
            <div className='Busi420114ynJump pyL'>
                <Title data={title} />
                <Rasterization>
                    <div className='ptL spaceBetween'>
                        <div className='w50 h100'>
                            {
                                data.data.map((item, index) => {
                                    return <div key={index} className='itemImg w100' style={{ display: index === this.state.showIndex ? '' : 'none' }}>
                                        <img className='w80' src={window.LOCALITYURL + item.img.data[0].attributes.url} alt=''></img>
                                    </div>
                                })
                            }
                        </div>
                        <div className='spaceBetween flexColumn w50'>
                            {
                                data.data.map((item, index) => {
                                    return <div key={index} className={`${index === this.state.showIndex ? 'active white' : ''} itemText pM`} onMouseEnter={() => this.changeIndex(index)} onClick={() => this.changeIndex(index)}>
                                        <span className='block fontL bold'>{item.title}</span>
                                        <span className='fontS'>{item.abstract}</span>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </Rasterization>
            </div>
        )
    }
}
