//产品-云服务-GPU服务器页面
import { Component } from 'react';
import load from '../tool/load'
import Loading from '../reuseComponent/Loading/index';
import { getGPUServersList } from '../API/agentset';

class GPUServer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            component: [],
            isLoading: false
        }
    }
    componentDidMount() {
        document.title = '产品-云服务-GPU服务器'
        getGPUServersList().then((res) => {
            let data = res.data[0].attributes.data;
            if (data) {
                this.setState({
                    component: data,
                    isLoading: false
                })
            }
        })
    }
    render() {
        let { isLoading, component } = this.state;
        if (isLoading) {
            return <Loading />
        } else {
            return (
                <div className="GPUServer animate__animated animate__fadeIn">
                    {
                        component.map((item, index) => {
                            let ComponentItem = load(item.componentName)
                            return <ComponentItem data={item} key={index} />
                        })
                    }
                </div>
            );
        }

    }
}

export default GPUServer;