//回到顶部
import { Component } from 'react';
import './style.scss'
// import { connect } from 'react-redux';
// import { serviceStatusFn } from '../../store/action';
import { withRouter } from 'react-router-dom';
import top from '../../images/回到顶部.png'

class BackTop extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        // //监听滚动条变化
        this.addEvent(window, 'scroll',
            () => {
                let scrollTop = document.documentElement.scrollTop;
                if (scrollTop > 300) {
                    this.setState({ backTop: true })
                } else {
                    this.setState({ backTop: false })
                }
            }
        );
    }
    addEvent = (obj, type, fn) => {
        if (obj.attachEvent) {
            obj.attachEvent('on' + type, function () {
                fn.call(obj);
            })
        } else {
            obj.addEventListener(type, fn, false);
        }
    }
    backTopFn = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    render() {
        let { backTop } = this.state;
        return (
            <div className="fixed BackTop" >
                {
                    <div className="radius100 bgBlue" onClick={this.backTopFn} style={{ display: backTop ? 'block' : 'none' }}>
                        <img className="icon pointer" src={top} alt="" />
                    </div>
                }
            </div>
        );
    }
}

// const mapStateToProps = (state) => {
//     return {
//         serviceStatus: state.serviceStatus,
//         serviceData: state.serviceData
//     }
// }
// const mapDispatchToProps = (dispatch) => {
//     return {
//         increment(status, data) {
//             dispatch(serviceStatusFn(status, data));
//         },
//     }
// }
// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BackTop));
export default (withRouter(BackTop));