//按钮组件
import { Component } from 'react';
import './style.scss'
import { withRouter } from 'react-router-dom';
import { throttle } from '../../tool/throttle';
class Buttons extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
    }

    render() {
        let data = this.props.data;
        let icon = this.props.icon;
        let onClick = this.props.onClick;
        return (
            <div>
                <button type="button" onClick={onClick ? throttle(onClick, 3000, true) : null} style={data.style} className="borderTransparent pointer Buttons flexAllCenter">{data.text}
                    {
                        icon && < img className="mlS icon" src={icon} alt="" />
                    }
                </button>
            </div>
        );
    }
}

export default withRouter(Buttons);