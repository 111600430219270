// 产品-云服务-产品功能
import React, { Component } from 'react'
import Title from '../../reuseComponent/Title/index'
import Rasterization from '../../tool/Rasterization';
import './style.scss'

export default class Busi230060yn extends Component {
    render() {
        let data = this.props.data
        let title = {
            name: data.title,
            content: data.abstract,
            titleStyle: {
                color: "#000",
                textAlign: "center"
            },
            abstractStyle: {
                color: "#F0F0F0",
                textAlign: "center"
            }
        }
        return (
            <div className='Busi230060yn pyL'>
                <Title data={title} />
                <Rasterization>
                    <div className='flexWrap ptL'>
                        {
                            data.data.map((item, index) => {
                                return <div key={index} className='w33 item alignCenter flexColumn pyL'>
                                    <div className='imgBox flexAllCenter'>
                                        <img src={window.LOCALITYURL + item.img.data[0].attributes.url} alt=''></img>
                                    </div>

                                    <p className='fontXl ptM bold'>{item.title}</p>
                                    <span className='fontM w50 textCenter'>{item.abstract}</span>
                                </div>
                            })
                        }
                    </div>

                </Rasterization>
            </div>
        )
    }
}
