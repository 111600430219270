
//案例详情页面 
import { Component } from 'react';
import { getCaseDetailList } from '../API/agentset'
import Loading from '../reuseComponent/Loading/index';
import load from '../tool/load'

class CasesDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: false,
            component: []
        }
    }
    componentDidMount() {
        document.title = '案例详情'
        this.getList();
    }
    getList = () => {
        let data = {
            'populate': 'data.img,data.Breadcrumbs'
        }
        getCaseDetailList(data).then((res) => {
            let data = res.data[0].attributes.data;
            if (data) {
                this.setState({
                    component: data,
                    isLoading: false
                })
            }
        })
    }

    render() {
        let { isLoading, component } = this.state;
        if (isLoading) {
            return <Loading />
        } else {
            return (
                <div className="casesDetail animate__animated animate__fadeIn">
                    {
                        component.map((item, index) => {
                            let ComponentItem = load(item.componentName)
                            return <ComponentItem data={item} key={index} />
                        })
                    }
                </div>
            );
        }
    }
}

export default CasesDetail;
