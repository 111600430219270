// 产品-GPU-应用场景
// 产品-云短信-应用场景
import React, { Component } from 'react'
import Title from '../../reuseComponent/Title/index'
import Rasterization from '../../tool/Rasterization';
import { Empty, Tabs } from 'antd';
import './style.scss'

const { TabPane } = Tabs;
export default class Busi250100ynJumpSwit extends Component {
    render() {
        let data = this.props.data

        let farArr = data.tabs
        let children = data.data

        farArr.forEach(item => {
            item.children = [];
            children.forEach(i => {
                if (item.key === i.key) {
                    item.children.push(i)
                }
            })
        })

        let title = {
            name: data.title,
            content: data.abstract,
            titleStyle: {
                color: "#000",
                textAlign: "center"
            }
        }
        return (
            <div className='Busi250100ynJumpSwit bgFA pyL'>
                <Title data={title} />
                <Rasterization>
                    <div className='mtL content'>
                        <Tabs defaultActiveKey="0">
                            {
                                farArr.map((item, index) => {
                                    return <TabPane tab={item.title} key={index} className='flex pyM h100'>
                                        <div className='w40 flexAllCenter h100'>
                                            {
                                                item.img.data ?
                                                    <div className='leftImg'>
                                                        <img className='w100 h100' src={window.LOCALITYURL + item.img.data[0].attributes.url} alt=''></img>
                                                    </div>
                                                    :
                                                    <Empty></Empty>
                                            }
                                        </div>
                                        <div className='w60 h100 prL'>
                                            {
                                                item.children.map((i, index) => {
                                                    return <div key={index}>
                                                        <span className='fontL bold block'>{i.title}</span>
                                                        {
                                                            i.abstract ?
                                                                <span className='pyS iAbstract fontM'>{i.abstract}</span>
                                                                : <></>
                                                        }
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </TabPane>
                                })
                            }
                        </Tabs>
                    </div>
                </Rasterization>
            </div>
        )
    }
}