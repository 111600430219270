// 首页-成功案例
import React, { Component } from 'react'
import Title from '../../reuseComponent/Title'
import Rasterization from '../../tool/Rasterization'
import Buttons from '../../reuseComponent/Buttons'
import './style.scss'
import { Link, withRouter } from 'react-router-dom'

class Bus140414ynJump extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0
        }
    }
    changeColor = (index) => {
        this.setState({
            index: index
        })
    }
    render() {
        let data = this.props.data
        let title = {
            name: data.title,
            content: '',
            titleStyle: {
                color: "#000",
                textAlign: "center"
            }
        }
        let btn = {
            text: data.button + '➝',
            style: {
                color: "#fff",
                background: "",
                borderColor: "#fff",
                padding: " 0.05rem .1rem",
                fontSize: ".24rem",
                margin: ".2rem 0"
            },
        }
        let btn2 = {
            text: data.button + '➝',
            style: {
                color: "#ccc",
                background: "",
                borderColor: "#ccc",
                padding: " 0.05rem .1rem",
                fontSize: ".24rem",
                margin: ".2rem 0"
            },
        }
        return (
            <div className='Bus140414ynJump'>
                <Title data={title} />
                <Rasterization>
                    <div className='spaceBetween pyL'>
                        {
                            data.data.map((item, index) => {
                                return <div key={index} className={`${index === this.state.index ? 'active' : ''} bgE8ECF1 pM item`} onMouseEnter={() => this.changeColor(index)} onClick={() => this.changeColor(index)}>
                                    <p className={`${index === this.state.index ? 'white' : ''} fontXl`}>{item.sid.data.attributes.title}</p>
                                    <div className={`${index === this.state.index ? 'white' : ''} fontL content clamp4`}
                                        dangerouslySetInnerHTML={{ __html: item.sid.data.attributes.content }}></div>
                                    {
                                        index === this.state.index ?
                                            <div>
                                                <Link to={{ pathname: `/CasesDetail/${item.sid.data.id}`, state: { Breadcrumbs: data.Breadcrumbs } }}>
                                                    <Buttons data={btn} ></Buttons>
                                                </Link>
                                            </div>
                                            :
                                            <div>
                                                <Link to={{ pathname: `/CasesDetail/${item.sid.data.id}`, state: { Breadcrumbs: data.Breadcrumbs } }}>
                                                    <Buttons data={btn2}></Buttons>
                                                </Link>
                                            </div>

                                    }

                                    <div className='caseImg'>
                                        <img className='w100 h100' src={window.LOCALITYURL + item.sid.data.attributes.img.data[0].attributes.url} alt=''></img>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </Rasterization>
            </div>
        )
    }
}
export default withRouter(Bus140414ynJump)