import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { getSolutionDetail } from '../../API/agentset';
import Loading from '../../reuseComponent/Loading';
import Rasterization from '../../tool/Rasterization'
import Breadcrumbs from '../../reuseComponent/Breadcrumbs/index'
import './style.scss'
class Solu510202yySwit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            isLoading: true,
        }
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.getList(id);

        let breadcrumbsData = []
        if (this.props.location.state) {
            this.props.location.state.Breadcrumbs.forEach(element => {
                breadcrumbsData.push(element)
            });
            sessionStorage.setItem('Breadcrumbs', JSON.stringify(breadcrumbsData))
        }
    }
    getList = (id) => {
        getSolutionDetail({ id: id }).then(res => {
            let data = res.data.attributes;
            if (data) {
                this.setState({
                    data: data,
                    isLoading: false,
                })
            }
        })
    }
    render() {
        let { isLoading, data } = this.state

        let breadcrumbs = JSON.parse(sessionStorage.getItem("Breadcrumbs"))

        if (isLoading) {
            return <Loading />
        } else {
            return (
                <div className='Solu510202yySwit myL'>
                    <Rasterization>
                        <Breadcrumbs data={breadcrumbs} />

                        <div className="borderB pbM mbM">
                            <div className="myM">
                                <p className="textCenter fontXxl mbM">{data.title}</p>
                            </div>

                            <div className="flexAllCenter flexColumn">
                                <div className="preWrap fontM" dangerouslySetInnerHTML={{ __html: data.content }}></div>
                                {
                                    data.img.data.length >= 2 ?
                                        <div className='ptM solutionImg'>
                                            <img className='w100' src={window.LOCALITYURL + data.img.data[0].attributes.url} alt='banner'></img>
                                            <img className='w100 mtS' src={window.LOCALITYURL + data.img.data[1].attributes.url} alt='banner'></img>
                                        </div>
                                        :
                                        <div className='ptM solutionImg'>
                                            <img className='w100' src={window.LOCALITYURL + data.img.data[0].attributes.url} alt='banner'></img>
                                        </div>
                                }
                            </div>
                        </div>
                    </Rasterization>
                </div>
            )
        }
    }
}
export default withRouter(Solu510202yySwit)