
// 产品-软件开发-为什么选择我们
import { Component } from 'react';
import './style.scss'
import Rasterization from '../../tool/Rasterization'
import Title from '../../reuseComponent/Title/index'
class Busi240800yn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }
    render() {
        let data = this.props.data;
        let title = {
            name: data.title,
            content: data.abstract,
            titleStyle: {
                color: "#000",
                textAlign: "center"
            }
        }
        return (
            <div className="Busi240800yn myL bgWhite">
                <Rasterization>
                    <Title data={title} />
                    <div className="flex spaceBetween flexWrap content">
                        {
                            data.data && data.data.map((item, index) => {
                                return <div key={index} className="item w25 ptL flexAllCenter flexColumn">
                                    <img className="w50" src={window.LOCALITYURL + item.img.data.attributes.url} alt="" />
                                    <span className="fontXl color333 mtM textCenter bold">{item.title}</span>
                                </div>
                            })
                        }
                    </div>
                </Rasterization>
            </div>
        );
    }
}

export default Busi240800yn;
