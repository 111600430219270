// 产品-软件开发-客户案例
import { Component } from 'react';
import './style.scss'
import bgimg from '../../images/typeBg.png'
import Rasterization from '../../tool/Rasterization'
import Title from '../../reuseComponent/Title/index'
// import Empty from '../../reuseComponent/Empty/index'
import { Empty } from 'antd';
import { getPortalCaseListPage, getPortalCaseTypeList } from '../../API/agentset';

class Case440600yySwitJump extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            index: 0,
            allData: [],
            originalData: [],
            flag: true,
            page: 1,
            pageSize: 6,
            typeId: 1,
        }
    }
    componentDidMount() {
        this.getTypeList();
    }
    getTypeList = () => {
        let data = {
            "populate": "img,case,caseType",
            "pagination[page]": 1,
            "pagination[pageSize]": 6,
        }
        getPortalCaseTypeList(data).then((res) => {
            this.setState({
                originalData: res.data,
                typeId: res.data[0].id
            })
            this.getList(this.state.page, this.state.pageSize, res.data[0].id);
        })
    }
    getList = (page, pageSize, typeId) => {
        this.setState({
            page: page,
            pageSize: pageSize,
            typeId: typeId
        })
        let pages = {
            "populate": "img,case,caseType",
            "pagination[page]": page,
            "pagination[pageSize]": pageSize,
        }

        let data = {
            ...pages, ...(typeId ? { "filters[case][id]": typeId } : {})
        }
        getPortalCaseListPage(data).then((res) => {
            this.setState({
                allData: res.data
            })
        })
    }
    tabFn = (event) => {
        let index = event.currentTarget.getAttribute("data-index") * 1;
        this.setState({
            index: index
        })
    }
    render() {
        let data = this.props.data;
        let { originalData, allData, typeId } = this.state;
        let title = {
            name: data.title,
            content: '',
            abstract: data.abstract,
            titleStyle: {
                color: "#000",
                textAlign: "center"
            }
        }
        return (
            <div className="Case440600yySwitJump pyL mtL bgF789">
                <Rasterization>
                    <Title data={title} />
                    <div className="relative flex bg spaceBetween flexWrap mtL pS noRepeat" style={{ backgroundImage: `url(${bgimg})` }}>
                        <div className="absolute content h100 tl0 w100">
                            {
                                originalData && originalData.map((item, index) => {
                                    return <div className="w100" key={index}>
                                        <span data-index={index} onClick={() => { this.getList(1, 6, item.id) }} className={`mtM fontXl color333 itemType w20 tab textCenter pointer  ${typeId === item.id && 'active'}`}>{item.attributes.name}</span>
                                        <div className={`hiden ${typeId === item.id ? 'activeAnimate' : ''}`}>
                                            <div className="w100 h100 bl0 flexWrap bgWhite" style={{ borderRadius: '.4rem' }}>
                                                {
                                                    allData.length > 0 ? allData.map((i, n) => {
                                                        return <div key={n} className="spaceBetween w33 item h50 pM borderB borderR bgWhite flexColumn spa">
                                                            {
                                                                i.attributes.img.data[1] && <img className="img mM" src={window.LOCALITYURL + i.attributes.img.data[1].attributes.url} alt="" />
                                                            }
                                                            <span className="fontL slogan">{i.attributes.content}</span>
                                                            <div>
                                                                <p className="bar fontM plS clamp2">{i.attributes.type}</p>
                                                                <div className="flexNoWrap tagBox">
                                                                    {
                                                                        i.attributes.caseType.map((i, n) => {
                                                                            return <span className="mrS pxS fontXxs borderHigh flexAllCenter tag colorHigh" key={n}><nobr>{i.text}</nobr></span>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }) :
                                                        <div className="w100 h100 flexAllCenter"><Empty /></div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </Rasterization>
            </div>
        );
    }
}

export default Case440600yySwitJump;
