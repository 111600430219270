// 产品-GPU-我们的服务
import React, { Component } from 'react'
import Title from '../../reuseComponent/Title/index'
import Rasterization from '../../tool/Rasterization';
import './style.scss'

export default class Busi140040yn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
            },
            index: 0
        }
    }
    changeColor = (index) => {
        this.setState({
            index: index
        })
    }
    render() {
        let data = this.props.data
        let title = {
            name: data.title,
            content: '',
            titleStyle: {
                color: "#000",
                textAlign: "center"
            }
        }
        return (
            <div className='Busi140040yn pyL'>
                <Title data={title} />
                <Rasterization>
                    <div className='flexAllCenter ptL'>
                        {
                            data.data.map((item, index) => {
                                return <div key={index} className={`${index & 1 ? 'bgF789' : 'bgGray'} ${index === this.state.index ? 'active' : ''} pxL item justifyCenter flexColumn`} onMouseEnter={() => this.changeColor(index)} onClick={() => this.changeColor(index)}>
                                    <div className='h40 alignCenter'>
                                        <img src={window.LOCALITYURL + item.img.data[index === this.state.index ? 0 : 1].attributes.url} alt=''></img>
                                    </div>
                                    <div className='h40 '>
                                        <p className={`${index === this.state.index ? 'white' : ''} fontXl`}>{item.title}</p>
                                        <span className={`${index === this.state.index ? 'colorDB' : 'color666'} fontL overflowYAuto`}>{item.abstract}</span>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </Rasterization >
            </div >
        )
    }
}