import React, { Component } from 'react'
import Buttons from '../../reuseComponent/Buttons'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setCustomerStatus } from '../../store/action';

class Othe210101ynJump extends Component {
    render() {
        let data = this.props.data
        let btn = {
            text: data.button,
            style: {
                color: "#fff",
                fontSize: ".24rem",
                border: '2px solid #FFFFFF',
                borderRadius: '32px',
                padding: '15px 85px'
            },
        }
        return (
            <div className='Othe210101ynJump flexAllCenter flexColumn'
                style={{
                    width: '100%',
                    height: '5.5rem',
                    background: `url("${window.LOCALITYURL + data.data[0].img.data.attributes.url}") center center / cover no-repeat`,
                }} >
                <p className='white fontXxl'>{data.data[0].title}</p>
                <Buttons data={btn} onClick={() => { this.props.increment(true) }}></Buttons>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        increment(data) {
            dispatch(setCustomerStatus(data));
        },
    }
}
const mapStateToProps = (state) => {
    return {
        customer: state.customer,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Othe210101ynJump));