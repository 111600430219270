//公司官网头部导航组件
import { Component } from 'react';
import { Link } from 'react-router-dom'
import './style.scss'
import { withRouter } from 'react-router-dom';
import Buttons from '../../reuseComponent/Buttons/index'
import Rasterization from '../../tool/Rasterization';
import { Empty } from 'antd';

class Nav150102ynLogInLang extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            showIndex: 0,
            ProductisShow: false,
            SolutionisShow: false
        }
    }
    componentDidMount() {
        window.addEventListener('click', this.productClick)
        window.addEventListener('click', this.solutionClick)
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.productClick)
        window.removeEventListener('click', this.solutionClick)
    }

    productClick = (e) => {
        if (this.productComponent.contains(e.target)) {
            // console.log('在组件内')
        } else {
            // console.log('在组件外')
            this.setState({
                ProductisShow: false,
            })
        }
    }
    solutionClick = (e) => {
        if (this.solutionComponent.contains(e.target)) {
            // console.log('在组件内')
        } else {
            // console.log('在组件外')
            this.setState({
                SolutionisShow: false,
            })
        }
    }
    changeIndex = (index) => {
        this.setState({
            showIndex: index
        })
    }
    ProductMenuShow = () => {
        this.setState({
            ProductisShow: !this.state.ProductisShow
        })
    }
    SolutionMenuShow = () => {
        this.setState({
            SolutionisShow: !this.state.SolutionisShow
        })
    }
    // 子菜单跳转页面
    switchPage = (path) => {
        let w = window.open('_blank')
        w.window.location.href = path
        this.setState({
            ProductisShow: false,
            SolutionisShow: false
        })
    }
    goHome = () => {
        this.props.history.push('/Home')
    }
    render() {
        let data = this.props.data[0]
        let farArr = data.data;
        let children = data.children;
        farArr.forEach(item => {
            item.children = [];
            children.forEach(i => {
                if (item.key === i.key) {
                    item.children.push(i)
                }
            })
        })
        let btn = {
            text: data.button + '>',
            style: {
                color: "#3470F2",
                fontSize: ".24rem",
                padding: 0,
            },
        }
        return (
            <div className='Nav150102ynLogInLang absolute tl0 w100 zIndex'>
                <Rasterization>
                    <div className='alignCenter'>
                        <div className="logo flexAllCenter">
                            <img className="w100 pointer" src={window.LOCALITYURL + data.img.data.attributes.url} alt="" onClick={this.goHome} />
                        </div>

                        <div className='fontL w50 spaceBetween'>
                            {
                                farArr.map((item, index) => {
                                    if (item.key === 'product') {
                                        return <div key={index}
                                            ref={productComponent => this.productComponent = productComponent}
                                            onMouseOut={this.onmouseleave}
                                        >
                                            <span className='white fontM pointer' onClick={this.ProductMenuShow}>{item.name}</span>

                                            <div className='SecondaryMenu absolute bgWhite flex pM' style={{ display: this.state.ProductisShow ? "" : "none" }}>

                                                <div className='w10 prL'>
                                                    {
                                                        item.children.map((element, index) => {
                                                            return <div key={index} className={`${index === this.state.showIndex ? 'colorHigh' : ''} fontL pyS pointer`}>
                                                                <span onClick={() => this.changeIndex(index)}>{element.name}</span>
                                                            </div>
                                                        })
                                                    }
                                                </div>

                                                <div className='w90'>
                                                    {
                                                        item.children.map((element, index) => {
                                                            return <div key={index} className='flex h100' style={{ display: index === this.state.showIndex ? '' : 'none' }}>
                                                                {
                                                                    element.data.map((i, index) => {
                                                                        return <div key={index} className='spaceBetween flexColumn w100 h100 borderL pxL'>
                                                                            <div className='alignCenter'>
                                                                                {
                                                                                    i.img.data ?
                                                                                        <div className=''>
                                                                                            <img className="menuImg" src={window.LOCALITYURL + i.img.data.attributes.url} alt="" />
                                                                                        </div>
                                                                                        :
                                                                                        <Empty></Empty>
                                                                                }
                                                                                <span className='fontL black mlM '>{i.title}</span>
                                                                            </div>

                                                                            <span className='fontM'>{i.abstract}</span>

                                                                            <div>
                                                                                <Buttons data={btn} onClick={() => this.switchPage(i.path)}></Buttons>
                                                                            </div>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    } else if (item.key === 'solution') {
                                        return <div key={index}
                                            ref={solutionComponent => this.solutionComponent = solutionComponent}
                                        >
                                            <span className='white fontM pointer' onClick={this.SolutionMenuShow}>{item.name}</span>

                                            <div className='SecondaryMenu absolute bgWhite pM' style={{ display: this.state.SolutionisShow ? "" : "none" }}>
                                                {
                                                    item.children.map((element, index) => {
                                                        return <div key={index} className='flexAllCenter h100' style={{ display: index === this.state.showIndex ? '' : 'none' }}>
                                                            {
                                                                element.data.map((i, index) => {
                                                                    return <div key={index} className='solutionItem pxS flex'>
                                                                        {
                                                                            i.img.data ?
                                                                                <div className='w20 flexAllCenter'>
                                                                                    <img className="menuImg" src={window.LOCALITYURL + i.img.data.attributes.url} alt="" />
                                                                                </div>
                                                                                :
                                                                                <Empty></Empty>
                                                                        }
                                                                        <div className='w80 pointer' onClick={() => this.switchPage(i.path)}>
                                                                            <p className='fontL black'>{i.title}</p>
                                                                            <span className='fontM color797979'>{i.abstract}</span>
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    } else {
                                        return <Link key={index} to={item.path} className='white fontM flexAllCenter'>
                                            <span className='white'>{item.name}</span>
                                        </Link>
                                    }
                                })
                            }
                        </div>
                    </div>
                </Rasterization >
            </div >
        );
    }
}

export default withRouter(Nav150102ynLogInLang);
