
const myrem = (window, document) => {// 首先是一个立即执行函数，执行时传入的参数是window和document
    // 返回文档的root元素
    var docEl = document.documentElement;
    // 获取设备的dpr，即当前设置下物理像素与虚拟像素的比值
    var dpr = window.devicePixelRatio || 1;

    // 设置默认字体大小，默认的字体大小继承自body
    function setBodyFontSize() {
        if (document.body) {
            // 调整body标签的fontSize，fontSize = (12 * dpr) + 'px'
            document.body.style.fontSize = 12 * dpr + "px";
        } else {
            document.addEventListener("DOMContentLoaded", setBodyFontSize);
        }
    }
    setBodyFontSize();

    // set 1rem = viewWidth / 24
    function setRemUnit() {
        // 设置root元素的fontSize = 其clientWidth / 24 + 'px'
        var rem = ''
        if (docEl.clientWidth >= 1920) {
            // console.log("大屏幕宽",docEl.clientWidth)
            rem = docEl.clientWidth / 32;
        } else if (docEl.clientWidth >= 1520) {
            if (docEl.clientHeight >= 969) {
                // console.log("中屏幕正常倍数",docEl.clientWidth,docEl.clientHeight)
                rem = docEl.clientWidth / 26;
            } else {
                // console.log("中屏幕放大125%",docEl.clientWidth,docEl.clientHeight)
                rem = docEl.clientWidth / 26;
            }
        } else if (docEl.clientWidth >= 1000) {
            rem = docEl.clientWidth / 24;
        } else if (docEl.clientWidth >= 700) {
            rem = docEl.clientWidth / 24;
        } else if (docEl.clientWidth >= 600) {
            rem = docEl.clientWidth / 18;
        } else if (docEl.clientWidth >= 500) {
            rem = docEl.clientWidth / 20;
        } else if (docEl.clientWidth >= 400) {
            rem = docEl.clientWidth / 8;
        } else if (docEl.clientWidth >= 300) {
            rem = docEl.clientWidth / 8;
        } else if (docEl.clientWidth >= 200) {
            rem = docEl.clientWidth / 8;
        }
        docEl.style.fontSize = rem + "px";
        // let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
        // let htmlDom = document.querySelector('html')
        // htmlDom.style.fontSize = htmlWidth / 20 + 'px'
    }
    setRemUnit();

    // 当页面展示或重新设置大小的时候，触发重新
    window.addEventListener("resize", setRemUnit);
    window.addEventListener("pageshow", function (e) {
        if (e.persisted) {
            setRemUnit();
        }
    });

    // 检测0.5px的支持，支持则root元素的class中有hairlines
    if (dpr >= 2) {
        var fakeBody = document.createElement("body");
        var testElement = document.createElement("div");
        testElement.style.border = ".5px solid transparent";
        fakeBody.appendChild(testElement);
        docEl.appendChild(fakeBody);
        if (testElement.offsetHeight === 1) {
            docEl.classList.add("hairlines");
        }
        docEl.removeChild(fakeBody);
    }

}

export default myrem