// 产品-运维服务-产品系列
import React, { Component } from 'react'
import Title from '../../reuseComponent/Title'
import Rasterization from '../../tool/Rasterization'
import Buttons from '../../reuseComponent/Buttons'
import './style.scss'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setCustomerStatus } from '../../store/action';
class Othe120002ynJump extends Component {
    goTo = (url) => {
        let w = window.open('_blank')
        w.window.location.href = url
    }
    render() {
        let data = this.props.data
        let farArr = data.data
        let children = data.other

        farArr.forEach(item => {
            item.children = [];
            children.forEach(i => {
                if (item.key === i.key) {
                    item.children.push(i)
                }
            })
        })

        let title = {
            name: data.title,
            content: data.abstract,
            titleStyle: {
                color: "#000",
                textAlign: "center"
            },
            abstractStyle: {
                color: "#fff",
                textAlign: "center"
            }
        }
        let btn = {
            text: data.button,
            style: {
                color: "#000",
                background: '#FAFBFF',
                borderRadius: '4px',
                padding: ".1rem .6rem",
                fontSize: ".24rem",
                margin: ".2rem 0",
            },
        }
        let btn1 = {
            text: data.button2,
            style: {
                color: "#000",
                background: '#EDF0F7',
                borderRadius: '4px',
                padding: ".1rem .6rem",
                fontSize: ".24rem",
                margin: ".2rem 0"
            },
        }
        return (
            <div className='Othe120002ynJump bgEDF07F7 pyL mtL' >
                <Title data={title} />
                <Rasterization>
                    <div className='flexAllCenter h100 w100 ptL'>
                        {
                            data.data.map((item, index) => {
                                return <div key={index} className='item alignCenter spaceBetween flexColumn h100 w100 pxM'>
                                    <p className='w100 title textCenter fontL pyS'>{item.title}</p>
                                    {
                                        item.children.map((i, index) => {
                                            return <p key={index} className='textCenter fontM'>{i.text}</p>
                                        })
                                    }
                                    {
                                        index % 2 === 0 ?
                                            <Buttons data={btn} onClick={() => { this.goTo(item.url) }}></Buttons>
                                            :
                                            <Buttons data={btn1} onClick={() => { this.props.increment(true) }}></Buttons>
                                    }
                                </div>
                            })
                        }
                    </div>
                </Rasterization>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        increment(data) {
            dispatch(setCustomerStatus(data));
        },
    }
}
const mapStateToProps = (state) => {
    return {
        customer: state.customer,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Othe120002ynJump));
