// 首页-业务核心
import React, { Component } from 'react'
import Title from '../../reuseComponent/Title/index'
import Rasterization from '../../tool/Rasterization';
import './style.scss'

export default class Busi160060yn extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        let data = this.props.data
        let title = {
            // name: '业务核心',
            name: data.title,
            content: '',
            titleStyle: {
                color: "#000",
                textAlign: "center"
            }
        }
        return (
            <div className='Busi160060yn'>
                <Title data={title} />
                <Rasterization>
                    <div className='spaceBetween pyL'>
                        {
                            data.data.map((item, index) => {
                                return <div key={index} className='textCenter'>
                                    <div className='round'>
                                        <img className='w100 h100' src={window.LOCALITYURL + item.img.data.attributes.url} alt=''></img>
                                    </div>
                                    <p className='fontXl ptL'>{item.title}</p>
                                </div>
                            })
                        }
                    </div>
                </Rasterization>
            </div>
        )
    }
}