//标题组件
import React, { Component } from 'react'
import './style.scss'


export default class Title extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
    }
    render() {
        let data = this.props.data;
        return (
            <div>
                {
                    data && <div className="title">
                        {/* <div className="titleContent relative" style={data.style}> */}
                        <div className="titleContent relative">
                            {data.name && <p className={`${data.content ? 'absolute' : ''} w100 fontXxxl m0 bold`} style={data.titleStyle}>{data.name}</p>}
                            {data.content && <p className="w100 fontXxxl m0 textUp" style={data.abstractStyle}>{data.content}</p>}
                            {data.abstract && <p className="w50 fontL subhead ptM m0Auto textCenter">{data.abstract}</p>}
                        </div>
                    </div>
                }
            </div>
        )
    }
}
