import request from './request'
import Qs from 'qs'


/**
 * 获取公共数据  顶部和底部导航/路由
 * @param data
 */
export function getPublicList(data = {}) {
    return request({
        method: 'get',
        url: "/api/common-components",
        params: data
    });
}
/**
 * 获取首页数据
 * @param data
 */
export function getHomeList(data = {}) {
    return request({
        method: 'get',
        url: "/api/front-pages",
        params: data
    });
}

/**
 * 获取关于我们页数据
 * @param data
 */
export function getAboutList(data = {}) {
    return request({
        method: 'get',
        url: "/api/abouts?populate[0]=data.img&populate[1]=data.data.img&populate[2]=data.data.data&populate[3]=data.input",
        data: Qs.stringify(data)
    });
}

/**
 * 获取加入我们页数据
 * @param data
 */
export function getJoinUSList(data = {}) {
    return request({
        method: 'get',
        url: "/api/join-uses?populate[0]=data.img&populate[1]=data.data.img",
        data: Qs.stringify(data)
    });
}

/**
 * 获取产品云服务器页数据
 * @param data
 */
export function getCloudServersList(data = {}) {
    return request({
        method: 'get',
        url: "/api/cloud-servers?populate[0]=data.img&populate[1]=data.data.img&populate[2]=data.tabs.img",
        data: Qs.stringify(data)
    });
}

/**
 * 获取产品GPU服务器页数据
 * @param data
 */
export function getGPUServersList(data = {}) {
    return request({
        method: 'get',
        url: "/api/gpu-servers?populate[0]=data.img&populate[1]=data.data.img&populate[2]=data.tabs.img",
        data: Qs.stringify(data)
    });
}

/**
 * 获取产品运维服务页数据
 * @param data
 */
export function getOMList(data = {}) {
    return request({
        method: 'get',
        url: "/api/oms",
        params: data
    });
}

/**
 * 获取产品-软件服务页数据
 * @param data
 */
export function getSoftwareServersList(data = {}) {
    return request({
        method: 'get',
        url: "/api/software-services",
        params: data
    });
}

/**
 * 获取产品-软件开发页数据
 * @param data
 */
export function getSoftwareDevelopmentList(data = {}) {
    return request({
        method: 'get',
        url: "/api/software-developments?populate[0]=data.img&populate[1]=data.data.img",
        data: Qs.stringify(data)
    });
}

/**
 * 获取产品-云短信页数据
 * @param data
 */
export function getCloudSMSList(data = {}) {
    return request({
        method: 'get',
        url: "api/cloud-sms?populate[0]=data.img&populate[1]=data.data.img&populate[2]=data.data.data&populate[3]=data.tabs.img",
        data: Qs.stringify(data)
    });
}

/**
 * 获取解决方案页数据
 * @param data
 */
export function getSolutionList(data = {}) {
    return request({
        method: 'get',
        url: "/api/solutions",
        params: data
    });
}

/**
 * 获取案列表数据
 * @param data
 */
export function getCaseDataList(data = {}) {
    return request({
        method: 'get',
        url: "/api/case-datas",
        params: data
    });
}

/**
 * 获取案例页分类数据
 * @param data
 */
export function getPortalCaseTypeList(data) {
    return request({
        method: 'get',
        url: '/api/case-classifications',
        params: data
    });
}

/**
 * 获取案例数据
 * @param data
 */
export function getPortalCaseListPage(data) {
    return request({
        method: 'get',
        url: '/api/case-datas',
        params: data
    });
}

/**
 * 获取案例详情页数据
 * @param data
 */
export function getCaseDetailList(data = {}) {
    return request({
        method: 'get',
        url: "/api/case-details",
        params: data
    });
}

/**
 * 获取案例详情数据
 * @param data
 */
export function getCaseDetail(data = {}) {
    return request({
        method: 'get',
        url: "/api/case-datas/" + data.id + "?populate[0]=img&populate[1]=localizations",
        // url: "/api/case-datas" + data.id + "?populate[0]=data.img&populate[1]=localizations",
        data: Qs.stringify(data)
    });
}

/**
 * 根据案例ID查询上一页
 * @param data
 */
export function getPrevNews(data) {
    return request({
        method: 'get',
        url: '/api/case-datas',
        params: data
    });
}

/**
 * 根据案例ID查询下一页
 * @param data
 */
export function getNextNews(data) {
    return request({
        method: 'get',
        url: '/api/case-datas',
        params: data
    });
}

/**
 * 解决方案 
 * @param data
 */
export function getSolution(data) {
    return request({
        method: 'get',
        url: '/api/solution-datas',
        params: data
    });
}

/**
 * 解决方案详情 
 * @param data
 */
export function getSolutionDetailList(data = {}) {
    return request({
        method: 'get',
        url: '/api/solution-details',
        params: data
    });
}

/**
 * 获取解决方案详情数据
 * @param data
 */
export function getSolutionDetail(data = {}) {
    return request({
        method: 'get',
        url: "/api/solution-datas/" + data.id + "?populate[0]=img&populate[1]=localizations",
        data: Qs.stringify(data)
    });
}

/**
 * 提交消息表单
 * @param data
 */
export function postForm(data = {}) {
    return request({
        method: 'post',
        url: "/api/messages",
        data: Qs.stringify(data)
    });
}
/**
 * 提交查询表单
 * @param data
 */
export function postFormApplications(data = {}) {
    return request({
        method: 'post',
        url: "/api/applications",
        data: Qs.stringify(data)
    });
}
/**
 * 发送邮件
 * @param data
 */
export function sendEmail(data = {}) {
    return request({
        method: 'post',
        url: "/api/email",
        data: Qs.stringify(data)
    });
}