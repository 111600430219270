import React, { Component } from 'react'
import './style.scss'
import Rasterization from '../../tool/Rasterization';
import Buttons from '../../reuseComponent/Buttons/index'
import { setCustomerStatus } from '../../store/action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
class Ban410411ynSwitJump extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    incident = (url) => {
        let w = window.open('_blank')
        w.window.location.href = url
    }

    render() {
        let data = this.props.data
        let btn = {
            text: data.button,
            style: {
                color: "#fff",
                background: " #4170D0",
                borderRadius: "0.3rem",
                borderColor: "#305DF4",
                padding: ".1rem .6rem",
                textAlign: "center",
                fontSize: ".24rem"
            }
        }
        return (
            <div className='Ban410411ynSwitJump'>
                <div className='relative'>
                    <img src={window.LOCALITYURL + data.img.data[0].attributes.url} alt='banner' className='w100'></img>

                    <div className="h100 flexAllCenter flexColumn w100 zIndex absolute tl0 white">
                        <Rasterization>
                            <p className={`${data.textAlign === "center" ? 'textCenter' : 'w30'} ${data.color === '1' ? '' : 'Gradient'} fontXxxl  m0 pbL`}>{data.title}</p>
                            <span className={`${data.color === '1' ? '' : 'color w25'} fontL pbL`}>{data.abstract}</span>
                            <div className={`${data.textAlign === "center" ? 'flexAllCenter' : ''}`}>
                                {
                                    data.button ?
                                        data.url ?
                                            <Buttons data={btn} onClick={() => { this.incident(data.url) }} />
                                            :
                                            <Buttons data={btn} onClick={() => { this.props.increment(true) }} />
                                        :
                                        <></>
                                }
                            </div>
                        </Rasterization>
                    </div>
                    {
                        data.data &&
                        <div className='bannerContent w100 pyM absolute bl0'>
                            <Rasterization>
                                <div className='spaceBetween'>
                                    {
                                        data.data.map((item, index) => {
                                            return <div key={index} className='bannerText'>
                                                <span className='white fontL'>{item.title}</span>
                                                <p className='colorDB fontM'>{item.abstract}</p>
                                            </div>
                                        })
                                    }
                                </div>
                            </Rasterization>
                        </div>
                    }

                </div>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        increment(data) {
            dispatch(setCustomerStatus(data));
        },
    }
}
const mapStateToProps = (state) => {
    return {
        customer: state.customer,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Ban410411ynSwitJump));