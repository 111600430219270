// 关于我们 公司介绍
import React, { Component } from 'react'
import Rasterization from '../../tool/Rasterization';
import triangle from '../../images/Rectangle 761.png'
import Title from '../../reuseComponent/Title';
import './style.scss'

export default class AbouUs120100yn extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        let data = this.props.data;
        let title = {
            name: data.title,
            content: data.abstract,
            titleStyle: {
                color: "#000",
                textAlign: "center"
            },
            abstractStyle: {
                color: "#000",
                textAlign: "center"
            }
        }
        return (
            <div className='AbouUs120100yn pyL bgF789'>
                <Title data={title} />
                <Rasterization>
                    <div className='bgWhite pyM mtL border'>
                        <div className='flex mtM pxM'>
                            <div className='relative justifyCenter'>
                                <span className="h100 left fontXl color999"><nobr>{data.data[0].title}</nobr></span>
                                <img src={triangle} alt='' className='absolute w30 br0 mrM' />
                            </div>
                            <img src={window.LOCALITYURL + data.data[0].img.data[0].attributes.url} className='w50 img' alt='公司介绍' />

                            <p className='plL fontXl color333 text m0 alignCenter'>{data.data[0].abstract}</p>
                        </div>
                    </div>
                </Rasterization>
            </div>
        )
    }
}
