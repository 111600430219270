import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import rem from './tool/rem';
import { Component } from 'react';
import { getPublicList } from './API/agentset';
import Home from './pages/Home';
import Loading from './reuseComponent/Loading/index';
import About from './pages/About';
import JoinUs from './pages/JoinUs';
import CasesDetail from './pages/CasesDetail';
import SolutionDetail from './pages/SolutionDetail';
import CloudServer from './pages/CloudServer'
import GPUServer from './pages/GPUServer'
import CloudSMS from './pages/CloudSMS'
import OM from './pages/O&M'
import Software from './pages/Software'
import SoftwareDevelopment from './pages/SoftwareDevelopment'
import Solution from './pages/Solution'
import BackTop from './reuseComponent/BackTop';
import load from './tool/load'
import ScrollToTop from './tool/ScrollToTop'
import Float from './reuseComponent/Float/index'
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      router: [
        {
          path: "/Home",
          component: Home,
          name: "上海蓝正信息技术有限公司-首页",
          content: []
        },
        {
          path: "/CloudServer",
          component: CloudServer,
          name: "产品-服务器-云服务器",
          content: []
        },
        {
          path: "/GPU-Server",
          component: GPUServer,
          name: "产品-服务器-GPU云服务器",
          content: []
        },
        {
          path: "/Cloud-SMS",
          component: CloudSMS,
          name: "产品-服务器-云短信",
          content: []
        },
        {
          path: "/O&M",
          component: OM,
          name: "产品-运维服务",
          content: []
        },
        {
          path: "/Software",
          component: Software,
          name: "产品-软件服务",
          content: []
        },
        {
          path: "/SoftwareDevelopment",
          component: SoftwareDevelopment,
          name: "产品-软件开发",
          content: []
        },
        {
          path: "/Solution/:id",
          component: Solution,
          name: "解决方案",
          content: []
        },
        {
          path: "/About",
          component: About,
          name: "关于我们",
          content: []
        },
        {
          path: "/JoinUs",
          component: JoinUs,
          name: "联系我们",
          content: []
        }],
      isLoading: true,
      base: [],
      nav: [],
      record: [],
      customerService: [],
      component: []
    }
  }
  componentDidMount() {
    //调用初始化根元素rem配置
    rem(window, document);
    let data = {
      "populate": "Nav150102ynLogInLang.img,Nav150102ynLogInLang.data,Nav150102ynLogInLang.children.data.img,Bott460100ynJump.data.img,email,CustomerService411211yy.img,Reco110010ynJump.img"
    }
    getPublicList(data).then((res) => {
      let data = res.data[0].attributes;
      if (data) {
        let arr = []
        for (const key in data) {
          if (Array.isArray(data[key])) {
            let obj = {};
            obj.componentName = load(key);
            obj.data = data[key];
            arr.push(obj)
          }
        }
        let email = data.email.email;
        localStorage.setItem('email', email)
        this.setState({
          nav: arr[0],
          base: arr[1],//底部
          customerService: arr[2],
          record: arr[3],
          isLoading: false//避免异步执行未完成而render
        })
      }
    })
  }
  render() {
    let { isLoading, nav, base, customerService, record } = this.state;
    if (isLoading) {
      return <Loading />
    } else {
      return (
        <div className="App">
          <Router>
            <nav.componentName data={nav.data} children={nav.children} />
            <ScrollToTop>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/CasesDetail/:id" component={CasesDetail} />
                <Route exact path="/SolutionDetail/:id" component={SolutionDetail} />
                {
                  this.state.router.map((item, index) => {
                    return <Route key={index} exact path={item.path} component={item.component} />
                  })
                }
                <Redirect to="/Home" />
              </Switch>
            </ScrollToTop>
            <base.componentName data={base.data} children={<record.componentName data={record.data} />} />
            <customerService.componentName data={customerService.data} />
            <Float />
            <BackTop />
          </Router>
        </div>
      );
    }
  }
}

export default App;
