// 产品-软件服务-我们的优势
import React, { Component } from 'react'
import Title from '../../reuseComponent/Title'
import Rasterization from '../../tool/Rasterization'
import './style.scss'

export default class CorCult150050yn extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        let data = this.props.data
        let title = {
            name: data.title,
            content: '',
            titleStyle: {
                color: "#000",
                textAlign: "center"
            }
        }
        return (
            <div className='CorCult150050yn pyL'>
                <Title data={title} />
                <Rasterization>
                    <div className='spaceBetween ptL Con'>
                        {
                            data.data.map((item, index) => {
                                return <div key={index} className={`item w20 flexAllCenter flexColumn pL h100`}>
                                    <img src={window.LOCALITYURL + item.img.data[0].attributes.url} alt='' ></img>
                                    <span className='white fontL ptM'>{item.title}</span>
                                </div>
                            })
                        }
                    </div>
                </Rasterization>
            </div>
        )
    }
}
