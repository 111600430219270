// 加入我们-为什么加入我们
import React, { Component } from 'react'
import Title from '../../reuseComponent/Title';
import Rasterization from '../../tool/Rasterization';
import './style.scss'

export default class CorCult130030yn extends Component {
    render() {
        let data = this.props.data
        let title = {
            name: data.title,
            content: '',
            titleStyle: {
                color: "#000",
                textAlign: "center"
            }
        }
        return (
            <div className='CorCult130030yn pyL bgF789'>
                <Title data={title} />
                <Rasterization>
                    <div className='spaceBetween ptL'>
                        {
                            data.data.map((item, index) => {
                                return <div key={index} className='flexAllCenter flexColumn w30 bgWhite pL'>
                                    <div className='img'>
                                        <img src={window.LOCALITYURL + item.img.data[0].attributes.url} alt='' className='w100 h100' />
                                    </div>
                                    <p className='fontL bold mtS'>{item.title}</p>
                                    <span className='fontM pM'>{item.abstract}</span>
                                </div>
                            })
                        }
                    </div>

                </Rasterization>
            </div>
        )
    }
}
