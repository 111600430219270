// 产品-软件开发-定制版组件
import { Component } from 'react';
import './style.scss'
import { withRouter } from 'react-router-dom';
import Buttons from '../../reuseComponent/Buttons/index'
import Rasterization from '../../tool/Rasterization'
import img6 from '../../images/Group 915.png'
import img7 from '../../images/icon.png'
import { connect } from 'react-redux';
import { setCustomerStatus } from '../../store/action';

class Buy210111ynServ extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
    }
    render() {
        let data = this.props.data
        let btn = {
            text: data.button,
            style: {
                color: "#fff",
                background: "#305DF4",
                borderRadius: ".4rem",
                borderColor: "#305DF4",
                padding: " 0.14rem .6rem",
                fontSize: ".24rem"
            }
        }

        return (
            <div className="Buy210111ynServ w100 myL">
                <Rasterization>
                    <div className="relative radius w100">
                        <img className="w100" src={img6} alt="" />
                        <div className="absolute pxL w100 h100 tl0 alignCenter spaceBetween">
                            <div className="alignCenter">
                                <img className="w15 mrL" src={img7} alt="" />
                                <div className="white">
                                    <p className="fontXxxl">{data.title}</p>
                                    <p className="fontL">{data.abstract}</p>
                                </div>
                            </div>
                            <div>
                                <Buttons data={btn} onClick={() => { this.props.increment(true) }} />
                            </div>
                        </div>
                    </div>
                </Rasterization>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        increment(data) {
            dispatch(setCustomerStatus(data));
        },
    }
}
const mapStateToProps = (state) => {
    return {
        customer: state.customer,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Buy210111ynServ));