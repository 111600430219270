// 备案号组件
import React, { Component } from 'react'
import './style.scss'

export default class Reco110010ynJump extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isShow: false,
        }
    }
    render() {
        let data = this.props.data[0]
        return (
            <div className='Reco110010ynJump relative'>
                <div className='recordBox flexAllCenter'>
                    {
                        data.img.data === null ? <div></div> :
                            <div className='img'>
                                <img src={window.LOCALITYURL + data.img.data.attributes.url} alt='' className='w100 h100 prS' />
                            </div>

                    }
                    <a target="_blank" rel="noopener noreferrer" href={data.ICPCaseNumberURL} className="colorCECECE">
                        <span className='fontS prM'>{data.ICPCaseNumber}</span>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href={data.securityCassNumberURL} className="colorCECECE">
                        <span className='fontS'>{data.securityCassNumber}</span>
                    </a>
                </div>
            </div>
        )
    }
}
